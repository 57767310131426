<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/marketing.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Marketing Success</div>
            <h1>Marketing Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Marketing Success</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Marketing Success</div>
							<h2 class="display-4 font-weight-bold">EMPOWERING MARKETING SPECIALISTS.</h2>
							<p>
                                eFlex’s dedicated high-performance marketing teams are passionate about brand communication and marketing. We are eager to contribute to your company’s growth and success by generating leads and producing marketing campaign materials.
                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/marketing.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-6 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/marketing1.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Market Research and Analysis</h3>
												<p>
                                                    eFlex provides support in gathering and analyzing data on marketing tactics, consumers, and competitors to help develop visibility to target markets.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-6 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/marketing2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Inbound and Outbound Marketing Activities</h3>
												<p>
                                                    From email blasts, telemarketing, cold calling, or creating content to spread brand awareness and “get found” by your target market through search engines, social media, or content marketing, eFlex marketing success specialists will help get the engagement you need for your service.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'Marketing',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
